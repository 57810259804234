const ONLINE_COURSE_ID = "online-course"
const PRACTICE_TEST_ID = "practice-for-written-test"
const WRITTEN_TEST_ID = "written-test"
const ROAD_TEST_ID = "road-test"
const DRIVING_LESSONS_ID = "driving-lessons"
const phoneNumber = "(800) 489-1896"

export type LicensingStep = {
  title: string
  description: string
  link?: string
  linkText?: string
  id: string
}

export type LicensingSteps = {
  [usState: string]: {
    [path: string]: {
      label: string
      article?: string
      steps: LicensingStep[]
    }
  }
}

export const LICENSING_STEPS: LicensingSteps = {
  ca: {
    teen: {
      label: "Teen",
      article: "/article/how-to-apply-for-california-drivers-license-as-a-teen",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          description:
            "Before you can take your written test at the DMV, you must pass a state-approved Drivers Ed course and receive your completion certificate.",
          link: "/ca/course/drivers-ed",
          linkText: "Start Course",
        },
        {
          id: PRACTICE_TEST_ID,
          title: "Practice for Written Test",
          description:
            "Once you have your completion certificate, it is time to practice! Take our practice test questions to ace your exam on the first try!",
          link: "/dmv-practice-test",
          linkText: "Start Practicing",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at DMV",
          description:
            "Ready to go? Make a reservation at the DMV and go pass your test! Once you pass, you will receive your permit.",
          link: "https://www.dmv.ca.gov/portal/appointments/select-appointment-type",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "Passed your test? Great! Now you are ready to take your first driving lessons. Reserve your spot now so you can get going as soon as you have your permit.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After completing your driving lessons, you can take your road test. Make a reservation at the DMV and go pass your test to receive your license.",
          link: "https://www.dmv.ca.gov/portal/appointments/select-appointment-type",
          linkText: "Make Appointment",
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: PRACTICE_TEST_ID,
          title: "Practice for Written Test",
          description:
            "Take our practice test questions to ace your exam on the first try so you can quickly earn your permit!",
          link: "/dmv-practice-test",
          linkText: "Start Practicing",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at DMV",
          description:
            "Ready to go? Make a reservation at the DMV and go pass your test! Once you pass, you will receive your permit.",
          link: "https://www.dmv.ca.gov/portal/appointments/select-appointment-type",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional for adults, we highly recommend driving lessons to build your driving foundation.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After completing your driving lessons, you can take your road test. Make a reservation at the DMV and go pass your test to receive your license.",
          link: "https://www.dmv.ca.gov/portal/appointments/select-appointment-type",
          linkText: "Make Appointment",
        },
      ],
    },
  },
  co: {
    teen: {
      label: "Teen",
      article: "/article/how-to-apply-for-colorado-drivers-license-as-a-teen",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          description:
            "If you are under 16, you'll need to start by passing your Drivers Ed course, which is then reported to the state so you can take your written test.",
          link: "/co/course/drivers-ed",
          linkText: "Start Course",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          description:
            "Schedule your appointment at the DMV and pass your written test to earn your permit.",
          link: "https://dmv.colorado.gov/AppointmentScheduling",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "Passed your test? Great! If you are under 16.5 years of age, you are required to take six hours of driving lessons.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After completing your driving lessons, you can take your road test. Make a reservation at the DMV and pass your test to receive your license. Want to skip the line at the DMV? Book your road test with us!",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
    adult: {
      label: "Adult",
      article: "/article/how-to-apply-for-colorado-drivers-license-as-a-teen",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at DMV",
          description:
            "Ready to go? Make a reservation at the DMV and go pass your test! Once you pass, you will receive your permit.",
          link: "https://dmv.colorado.gov/AppointmentScheduling",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional for adults, we highly recommend driving lessons to build your driving foundation.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "To get your license, you must now pass your road test. Make a reservation at the DMV or skip the lines and book it with us!",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
  },
  il: {
    teen: {
      label: "Teen",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Drivers Ed Classroom Course",
          description:
            "Teens must complete an in-person classroom course first.",
          link: "/il/course/drivers-ed",
          linkText: "Enroll Now",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          description:
            "After completing your classroom course, you can take your written test to earn your permit.",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "We offer packages covering the state-required 8 hours of private instruction.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After taking your lessons, practicing for 50 hours with your parents, and holding a permit for 9+ months, pass your driving test at the SOS office to earn your license. Want to skip the lines at SOS? Book your road test with us!",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Take Online Course (ages 18-20 only)",
          description:
            "If you are between the ages of 18 and 20, you will need to take a six-hour online course.",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          description:
            "If you are 21 or older, or have completed your online course, you can take your written test to earn your permit at your local SOS office.",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "Although not required for adult students, taking lessons is the best way to start your driving journey safely.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "Head back to the SOS office to take your road test and earn your license!",
        },
      ],
    },
  },
  in: {
    teen: {
      label: "Teen",
      article:
        "/article/how-to-apply-for-indiana-drivers-license-as-a-teen-under-18",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          description:
            "Before you can take your written test at the BMV, you must be enrolled in a state-approved Drivers Ed course. We highly recommend you complete this course before taking your written test.",
          link: "/in/course/drivers-ed",
          linkText: "Start Course",
        },
        {
          id: "driving-lessons-registration",
          title: "Register for Driving Lessons",
          description:
            "The second requirement before taking your written test is to register for driving lessons. Once registered, you will receive your CDE (certificate).",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at BMV",
          description:
            "Registered for Drivers Ed and Driving Lessons, and have your CDE? Time to take your written test at the BMV!",
          link: "https://www.in.gov/bmv/branch-locations-and-hours/bmv-branch-map/make-a-branch-appointment/",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "Passed your test? Great! Now you are ready to take your first driving lessons. Reserve your spot now so you can get going as soon as you have your permit.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After completing your driving lessons, you can take your road test. Make a reservation at the BMV and pass your test to receive your license or skip the lines and take your road test with us.",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
    adult: {
      label: "Adult",
      article:
        "/article/how-to-apply-for-indiana-drivers-license-as-a-teen-over-18",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at BMV",
          description:
            "If you are over 18 but under 21, you will need to start by taking the written test at your local BMV.",
          link: "https://www.in.gov/bmv/branch-locations-and-hours/bmv-branch-map/make-a-branch-appointment/",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional for adults, we highly recommend driving lessons to build your driving foundation from professional instructors.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After completing your driving lessons, it's time to take your road test. Make a reservation and go pass your test to receive your license!",
          link: "https://www.in.gov/bmv/branch-locations-and-hours/bmv-branch-map/make-a-branch-appointment/",
          linkText: "Make Appointment",
        },
      ],
    },
  },
  mo: {
    teen: {
      label: "Teen",
      article: "/article/how-to-apply-for-missouri-drivers-license-as-a-teen",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          description:
            "While optional, we recommend learning the rules of the road and driving fundamentals with our premium online Drivers Ed course.",
          link: "/mo/course/drivers-ed",
          linkText: "Start Course",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          description:
            "To get your Instruction Permit, you must first pass the vision, road sign and written test at an examination station.",
          link: "http://www.mshp.dps.missouri.gov/MSHPWeb/PatrolDivisions/DED/index.html",
          linkText: "Find Location",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional in Missouri, we highly recommend joining thousands of other young drivers taking driving lessons from professional instructors.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Driving Test",
          description:
            "After completing your driving lessons, it's time to take your road test. Go pass your test to receive your license!",
          link: "http://www.mshp.dps.missouri.gov/MSHPWeb/PatrolDivisions/DED/index.html",
          linkText: "Find Location",
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          description:
            "To get your Instruction Permit, you must first pass the vision, road sign and written test at an examination station.",
          link: "http://www.mshp.dps.missouri.gov/MSHPWeb/PatrolDivisions/DED/index.html",
          linkText: "Find Location",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional in Missouri, we highly recommend joining thousands of other young drivers taking driving lessons from professional instructors.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Driving Test",
          description:
            "After completing your driving lessons, it's time to take your road test. Go pass your test to receive your license!",
          link: "http://www.mshp.dps.missouri.gov/MSHPWeb/PatrolDivisions/DED/index.html",
          linkText: "Find Location",
        },
      ],
    },
  },
  nv: {
    teen: {
      label: "Teen",
      article: "/article/how-to-apply-for-nevada-drivers-license-as-teen",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          description:
            "Before you can take your written test at the DMV, you must pass a state-approved Drivers Ed course and receive your completion certificate.",
          link: "/nv/course/drivers-ed",
          linkText: "Start Course",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at DMV",
          description:
            "Ready to go? Make a reservation at the DMV and go pass your test! Once you pass, you will receive your permit.",
          link: "https://dmv.nv.gov/appointments.htm",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional in Nevada, we highly recommend taking at least three driving lessons with a professional instructor.",
          link: "/packages",
          linkText: "Book Now",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After holding your permit for six months, you can take your road test. Make a reservation at the DMV and go pass your test to receive your license.",
          link: "https://dmvapp.nv.gov/DMV/DL/DriveTestSchedulerPublic/DriveTest_Input.aspx",
          linkText: "Make Appointment",
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test at DMV",
          description:
            "Ready to go? Make a reservation at the DMV and go pass your test! Once you pass, you will receive your permit.",
          link: "https://dmvapp.nv.gov/DMV/DL/DriveTestSchedulerPublic/DriveTest_Input.aspx",
          linkText: "Make Appointment",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional for adults, we highly recommend driving lessons to build your driving foundation.",
          link: "/packages",
          linkText: "Book Lessons",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your Road Test",
          description:
            "After holding your permit for six months, you can take your road test. Make a reservation at the DMV and go pass your test to receive your license.",
          link: "https://dmvapp.nv.gov/DMV/DL/DriveTestSchedulerPublic/DriveTest_Input.aspx",
          linkText: "Make Appointment",
        },
      ],
    },
  },
  pa: {
    teen: {
      label: "Teen",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass written test at PENNDOT",
          description:
            "Before you can take your written test at PENNDOT, you'll need to be 16 years old. Pass your test to receive your Learner's Permit.",
          linkText: "Find a Location",
          link: "https://www.dmv.pa.gov/Driver-Services/Pages/Open-Locations.aspx",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take driving lessons",
          description:
            "While optional in Pennsylvania, we highly recommend taking at least one driving lesson with a professional instructor.",
          link: "/packages",
          linkText: "Book Now",
        },
        {
          id: "practice-hours",
          title: "Log your practice hours",
          description:
            "Before you can take your road test, you must log 65 hours of driving practice, including 10 hours at night and five (5) hours of bad weather driving.",
          linkText: "Learn More",
          link: "https://www.dmv.pa.gov/Driver-Services/Driver-Licensing/Pages/get-driver-license.aspx",
        },
        {
          id: ROAD_TEST_ID,
          title: "Take your Road Test & Get your Junior License",
          description:
            "After holding your permit for at least 6 months, you can take your road test. Want to skip the line at PENNDOT? Take your test with us!",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: WRITTEN_TEST_ID,
          title: "Pass written test at PENNDOT",
          description: "Pass your test to receive your Learner's Permit.",
          linkText: "Find a Location",
          link: "https://www.dmv.pa.gov/Driver-Services/Pages/Open-Locations.aspx",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take driving lessons",
          description:
            "While optional in Pennsylvania, we highly recommend taking at least one driving lesson with a professional instructor.",
          link: "/packages",
          linkText: "Book Now",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass your road test to get your license",
          description:
            "You must now pass your road test. Want to skip the line at PENNDOT? Take your test with us!",
          linkText: `Call ${phoneNumber} to book`,
          link: `tel:${phoneNumber}`,
        },
      ],
    },
  },
  tx: {
    "teen-parent-taught": {
      label: "Teen (Parent-Taught)",
      steps: [
        {
          id: "request-guide",
          title: "Request Parent-Taught Guide",
          link: "https://www.tdlr.texas.gov/driver/parenttaughtprogram.htm",
          linkText: "Request Guide",
          description:
            "Request your Parent Taught Driver Education Program Guide from the TDLR (this must be received before beginning the course)",
        },
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          link: "/tx/course/parent-taught",
          linkText: "Start Course",
          description:
            "Complete the 32 hour drivers ed course in no less than 16 days (Texas law only allows 2 hours of study per day) and we'll issue your course completion certificate right away",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          link: "https://public.txdpsscheduler.com/",
          linkText: "Schedule Appointment",
          description:
            "Bring your completion certificate to the DPS and pass your written test to receive your learner's permit",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Complete Behind-the Wheel Training",
          link: "/packages",
          linkText: "Book Now",
          description:
            "Complete 44 hours of parent-led behind-the-wheel training to receive your behind-the-wheel completion certificate",
        },
        {
          id: "impact-texas",
          title: "Complete ITTD Course",
          link: "https://impacttexasdrivers.dps.texas.gov/ittd/CourseOverview.aspx",
          linkText: "Start Now",
          description:
            "Complete the state required Impact Texas Teen Drivers (ITTD) Course",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass Driving Test",
          link: "https://www.dps.texas.gov/section/driver-license/schedule-your-driving-test-appointment",
          linkText: "Schedule Appointment",
          description:
            "Pass your behind-the-wheel test at the DPS to earn your license!",
        },
      ],
    },
    "teen-instructor-led": {
      label: "Teen (Instructor Led)",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          link: "/tx/course/drivers-ed",
          linkText: "Start Now",
          description:
            "Complete the 32 hour teen drivers ed course in no less than 16 days (Texas law only allows 2 hours of study per day) and we'll issue your course completion certificate right away",
        },
        {
          id: WRITTEN_TEST_ID,
          title: "Pass Written Test",
          link: "https://public.txdpsscheduler.com/",
          linkText: "Schedule Appointment",
          description:
            "Bring your completion certificate to the DPS and pass your written test to receive your learner's permit",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          link: "/packages",
          linkText: "Book Now",
          description:
            "Take 14 hours of professional driving instruction comprised of 7 driving hours and 7 observation hours (we offer those, too!) + complete 30 hours of additional driving practice to receive your behind-the-wheel completion certificate",
        },
        {
          id: "impact-texas",
          title: "Complete ITTD Course",
          link: "https://impacttexasdrivers.dps.texas.gov/ittd/CourseOverview.aspx",
          linkText: "Start Now",
          description:
            "Complete the state required Impact Texas Teen Drivers (ITTD) Course",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass Driving Test",
          link: "https://www.dps.texas.gov/section/driver-license/schedule-your-driving-test-appointment",
          linkText: "Schedule Appointment",
          description:
            "Pass your behind-the-wheel test at the DPS to earn your license!",
        },
      ],
    },
    adult: {
      label: "Adult",
      steps: [
        {
          id: ONLINE_COURSE_ID,
          title: "Pass Drivers Ed Course",
          link: "/tx/course/adult-drivers-ed",
          linkText: "Start Course",
          description:
            "Complete the 6 hour adult drivers ed course and we'll issue your completion certificate right away",
        },
        {
          id: DRIVING_LESSONS_ID,
          title: "Take Driving Lessons",
          description:
            "While optional as an adult, we highly recommend taking at least one driving lesson with a professional instructor.",
          link: "/packages",
          linkText: "Book Now",
        },
        {
          id: "impact-texas",
          title: "Complete ITAD Course",
          link: "https://impacttexasdrivers.dps.texas.gov/ITAD/",
          linkText: "Start Now",
          description:
            "Complete the state required Impact Texas Adult Drivers (ITAD) Course",
        },
        {
          id: ROAD_TEST_ID,
          title: "Pass Driving Test",
          link: "https://www.dps.texas.gov/section/driver-license/schedule-your-driving-test-appointment",
          linkText: "Schedule Appointment",
          description:
            "Pass your behind-the-wheel test at the DPS to earn your license!",
        },
      ],
    },
  },
}

// This is used as a look up for online courses so they can just
// use their course type to find the corresponding licensing path.
export const COURSE_TYPE_TO_PATH: {
  [state: string]: { [courseType: string]: string }
} = {
  ca: { "drivers-ed": "teen" },
  co: { "drivers-ed": "teen" },
  il: { "drivers-ed": "teen" },
  in: { "drivers-ed": "teen" },
  nv: { "drivers-ed": "teen" },
  mo: { "drivers-ed": "teen" },
  tx: {
    "drivers-ed": "teen-instructor-led",
    "adult-drivers-ed": "adult",
    "parent-taught": "teen-parent-taught",
  },
}
